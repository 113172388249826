import React, { useContext } from "react";
import tw, { styled } from "twin.macro";
import { ThemeContext } from "./themes/theme-context";

const TopNavStyle = styled.nav(({ textColor }) => [
	tw`flex flex-row justify-center items-center space-x-2 pl-5 pr-5 pb-3 lg:pl-0 lg:pr-0 lg:w-1/2 lg:justify-end lg:pb-0 lg:space-x-4`,
	textColor,
]);

const StyledButton = tw.a`flex flex-row shadow-top-nav-button text-sm leading-sans-sm items-center justify-center rounded-full w-1/2 py-2 md:w-1/3 md:text-base md:leading-sans-base md:px-5 md:py-3 lg:w-auto lg:text-lg lg:leading-sans-lg lg:px-6 lg:py-3 bg-white transition duration-150 ease-linear transform hover:scale-105`;

const StyledIcon = tw.div`w-4 mr-3 lg:w-6`;

const TopNavigation = ({ data }) => {
	// console.log(data);
	const phoneNumber = data.phoneNumber;
	const chatLink = data.chatLink;

	const { theme } = useContext(ThemeContext);
	// console.log(theme);

	return (
		<TopNavStyle textColor={theme.textColor}>
			<StyledButton href={`tel:${phoneNumber.link}`}>
				<StyledIcon>
					<img
						tw="w-full h-full"
						src={theme.iconPhone}
						alt={phoneNumber.label}
						aria-hidden="true"
					/>
				</StyledIcon>
				<div>{phoneNumber.label}</div>
			</StyledButton>
			<StyledButton href={chatLink.link} target="_blank" rel="noopener">
				<StyledIcon>
					<img
						tw="w-full h-full"
						src={theme.iconChat}
						alt={chatLink.label}
						aria-hidden="true"
					/>
				</StyledIcon>
				<div>{chatLink.label}</div>
			</StyledButton>
			<StyledButton href={`sms:${phoneNumber.link}`}>
				<StyledIcon>
					<img
						tw="w-full h-full"
						src={theme.iconText}
						alt={phoneNumber.label}
						aria-hidden="true"
					/>
				</StyledIcon>
				<div>Text {phoneNumber.label}</div>
			</StyledButton>
		</TopNavStyle>
	);
};

export default TopNavigation;
